import React, { Component } from "react";

//ButtonWizard, component for back and forward in the wizard
export default class ButtonWizard extends Component {
    render() {
        return (
            <input
                type="button"
                name="next"
                className="next action-button"
                value={this.props.text}
                onClick={this.props.btnClicked}
            />
        );
    }
}
