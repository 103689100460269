import React, { Component} from "react";
import {Card} from "react-bootstrap";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import {ListGroup, Tab} from 'react-bootstrap';
import 'moment/locale/it';
import 'moment-timezone';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';
import Utils from "../../Utils";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Placeholder from 'react-bootstrap/Placeholder';
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import moment from 'moment';

// Import Images
import imgPlaceholder from "../../../images/placeholderTicalo.png";
import iconDeselect from "../../../images/deselect.png";

//Elements
import Functions from "../../Functions";

const list = [
    {name: 'Gio'},
    {name: 'Ven'},
    {name: 'Sab'},
    {name: 'Dom'}
];

export default class SelectEntertainment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            titleButtonCustomer: "Torna al " + Utils.NAME_CUSTOMER,
            selected: "",
            showEvents:true,
            showHours:true,
            showToast:false,
            selDay : "",
            selHour : "",
            maxItemsForOrder: 0,
            reservationTime: "",
            msgError: null,
            isLoaded: false,
            isLoadedCard: false,
            showModalInfoEvent:false,
            selectedObjEvent:null,
            items: [],
            itemsGrouped: [],
            itemsGroupedSubscription: [],
            itemsGroupedSubscriptionFinal: [],
            itemsGroupedSubscriptionTemp: [],
            itemsGroupedSubscriptionTempFinal: [],
            itemsGroupedTipology: [],
            itemsSelected: [],
            itemsHour: [],
            resultData : [],
            arrStrHour: [],
            selectedname:null,
            nameindex:-1,
            selectedtime:null,
            timeindex:-1,
            dayClicked:false
        };
        moment.locale('it')

    }

    componentDidMount() {

        if (this.props.values.itemsEvent.length > 0){
            this.fetchEntertainments(this.props.values.itemsEvent);
        }

    }

    fetchEntertainments(events) {
        events.forEach((data)=>{
            this.state.resultData.push(data);
        });
        this.addEntertainmentData(this.state.resultData);
    }

    addEntertainmentData(json){

        //var defaultValue = "";
        var arrEventGrouped = [];
        var arrEventSubscription = [];
        var arrEventSubscriptionFinal = [];
        var arrHours = [];
        let event = null;
        let checkItemsSelected = false;

        if (this.state.itemsSelected.length > 0){
            checkItemsSelected = true;
        }

        for (let i=0; i < json.length; i++) {
            event = json[i];
            event.selectedEvent = false;
            event.tipology = "";
            event.publication = "";

            if(!checkItemsSelected){
                event.imgEvent = "";
            }
            let dict = {};
            dict[event.name.it] = event;
            arrEventGrouped.push(dict);

            /*let metaProperties = Object(event.item_meta_properties);
            if (Object.keys(event.item_meta_properties).length > 0) {
                let dict = {};
                for (var j=0; j < Object.keys(event.item_meta_properties).length; j++) {
                    var keyMeta = Object.keys(event.item_meta_properties)[j];
                    var valueMeta = Object.values(event.item_meta_properties)[j];
                    if (keyMeta === Utils.SlugEvent){
                        defaultValue = valueMeta;
                        if(defaultValue === valueMeta){
                            dict[defaultValue] = event;
                        }
                    }else{
                        dict[keyMeta] = event
                    }

                }
                arrEventGrouped.push(dict);
            }*/

        }

        arrEventGrouped.forEach((value, i) => {
            if (Object.keys(value) !== 0) {
                var keyEvent = Object.keys(value)[0];
                var event = value[keyEvent];

                //var dateFrom = event.date_from;
                var dateFromString = new Date(event.date_from);
                const hourTemp = moment(new Date(dateFromString))
                    .utc().format("LT");

                //var dateString = dateFrom.toLocaleString('it-IT');
                //var day = new Date(dateString);
                //var n = day.toLocaleString('it-IT');

                //var time = dateString;
                //var t = new Date(time);
                /*var hr = ("0" + t.getHours()).slice(-2);
                var hourFinal = hr + ":00";*/
                const hourFinal = hourTemp;

                if (Object.keys(event.item_meta_properties).length > 0) {

                    for (let j=0; j < Object.keys(event.item_meta_properties).length; j++) {
                        var keyMeta = Object.keys(event.item_meta_properties)[j];
                        var valueMeta = Object.values(event.item_meta_properties)[j];

                        if (keyMeta === 'abbonamento'){
                            if(valueMeta === 's'){
                                arrEventSubscription.push(event);
                                if (arrHours.length > 0){
                                    let result;
                                    result = arrHours.filter((hour) => hour.includes(hourFinal));
                                    if (result.length === 0){
                                        arrHours.push(hourFinal);
                                    }
                                }else{
                                    arrHours.push(hourFinal);
                                }
                            }
                        }

                    }
                }
            }
        });

        for (let z=0; z < arrEventSubscription.length; z++) {
            event = arrEventSubscription[z];
            //let metaProperties = Object(event.item_meta_properties);
            if (Object.keys(event.item_meta_properties).length > 0) {
                for (let j=0; j < Object.keys(event.item_meta_properties).length; j++) {
                    var keyMeta = Object.keys(event.item_meta_properties)[j];
                    var valueMeta = Object.values(event.item_meta_properties)[j];
                    if (keyMeta === Utils.SlugTipology){
                        event.tipology = valueMeta
                    }
                    if (keyMeta === Utils.SlugPubblication){
                        event.publication = valueMeta
                    }
                }
            }

        }

        if (arrEventSubscription.length > 0){
            arrEventSubscription.sort(function( a , b ){
                return a.date_from > b.date_from ? 1 : -1;
            });
        }


        let arrGroupedTipology = [];

        for (let o=0; o < arrEventSubscription.length; o++) {
            let eventFinal = arrEventSubscription[o];
            if (Object.keys(eventFinal.item_meta_properties).length > 0) {
                //for (var y=0; y < Object.keys(eventFinal.item_meta_properties).length; y++) {
                    if (eventFinal.tipology !== ""){
                        let valueTipology = eventFinal.tipology;
                        if (arrGroupedTipology.length === 0){
                            arrGroupedTipology.push(valueTipology);
                        }else{
                            let result = arrGroupedTipology.filter((kEvent) => kEvent.includes(valueTipology));
                            if (result.length === 0){
                                arrGroupedTipology.push(valueTipology);
                            }
                        }
                    }
                //}
            }

        }

        if (arrHours.length > 0){
            arrHours.sort(function( a , b ){
                return a > b ? 1 : -1;
            });
        }

        if (checkItemsSelected){
            this.setState({
                itemsGrouped: arrEventGrouped,
                itemsGroupedSubscription: arrEventSubscription,
                itemsGroupedSubscriptionFinal: arrEventSubscriptionFinal,
                itemsGroupedSubscriptionTemp: arrEventSubscription,
                itemsHour : arrHours,
                itemsGroupedTipology: arrGroupedTipology,
                isLoaded: true
            });
        }else{

            if (arrEventSubscription.length > 0){
                let headers = {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'authorization': 'Bearer ' + Utils.TOKEN_TICALO_OAUTH,
                        'Host': Utils.BASE_URL_SERVER_CUSTOM
                    }
                };

                if (this.props.values.organizer !== null){
                    let maxItemsOrder = false;
                    for (let y=0; y < arrEventSubscription.length; y++) {
                        let objEvent = arrEventSubscription[y];
                        let slugEvent = objEvent.slug;
                        const link = Utils.URL_API_SERVER_ORGANIZERS + this.props.values.organizer.slug + "/" + Utils.URL_API_SERVER_ORGANIZER_EVENTS + slugEvent + '/settings/';
                        fetch(link, headers)
                            .then((response) => response.json())
                            .then((responseJson) => {
                                if (responseJson != null) {
                                    let img = responseJson.logo_image.toString();
                                    if (maxItemsOrder === false){
                                        maxItemsOrder = true;
                                        maxItemsOrder = responseJson.max_items_per_order.toString();
                                        this.setState({
                                            maxItemsForOrder: responseJson.max_items_per_order.toString(),
                                            reservationTime: responseJson.reservation_time.toString()
                                        });
                                    }
                                    objEvent.imgEvent = img;
                                }
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                        if (y === arrEventSubscription.length - 1){
                            this.setState({
                                itemsGrouped: arrEventGrouped,
                                itemsGroupedSubscription: arrEventSubscription,
                                itemsGroupedSubscriptionFinal: arrEventSubscriptionFinal,
                                itemsGroupedSubscriptionTemp: arrEventSubscription,
                                itemsHour : arrHours,
                                itemsGroupedTipology: arrGroupedTipology,
                                isLoaded: true
                            });
                        }
                    }
                }else{
                    console.error("error organizer");
                }

            }
        }


    }

    handleCloseInfoEvent = () => { this.setState({'showModalInfoEvent':false})};
    handleShowInfoEvent = () => {this.setState({'showModalInfoEvent':true})};

    onSelectDay = (item, idx) => {

        let arrHoursAvailable = this.state.itemsHour;
        this.setState({
            arrStrHour: [],
            itemsSelected: []
        });

        const { selDay } = this.state;
        const { showHours } = this.state;

        if (selDay === ""){
            if (showHours){
                this.setState({
                    showHours: false,
                    selDay: item.name
                });
            }
        }else{
            if (selDay === item.name){
                this.setState({
                    showEvents: true
                });
            }else{
                this.setState({
                    showEvents: true,
                    selHour: "",
                    selDay: item.name
                });
            }
        }

        var { itemsGroupedSubscription } = this.state;
        //var { itemsGroupedSubscriptionFinal } = this.state;
        var { itemsGroupedSubscriptionTemp } = this.state;
        //var { itemsGroupedSubscriptionTempFinal } = this.state;

        if (itemsGroupedSubscriptionTemp.length > itemsGroupedSubscription.length){
            itemsGroupedSubscription = itemsGroupedSubscriptionTemp;
        }

        var arrStrHourTemp = [];
        for (var i=0; i < itemsGroupedSubscription.length; i++) {
            var event = itemsGroupedSubscription[i];
            event.selectedEvent = false;

            var dateFromString = new Date(event.date_from);
            const hourTemp = moment(new Date(dateFromString))
                .utc().format("LT");

            var day = new Date(dateFromString);

            //var time = dateFromString;
            //var t = new Date(time);

            /*var hr = ("0" + t.getHours()).slice(-2);
            const hourFinal = hr + ":00";*/
            const hourFinal = hourTemp;
            const dayFinal = day.toLocaleDateString('it-IT', { weekday: 'short' });

            if (dayFinal.toLowerCase() === this.state.selDay.toLowerCase()) {
                if (event.publication === ""){
                    let result;
                    result = arrHoursAvailable.filter((hour) => hour.includes(hourFinal));
                    if (result.length === 1){
                        let result1;
                        result1 = arrStrHourTemp.filter((hour) => hour.includes(hourFinal));
                        if (result1.length === 0){
                            arrStrHourTemp.push(result[0]);
                        }

                    }
                }
            }

        }

        this.setState({
            arrStrHour: arrStrHourTemp
        });

        if (arrStrHourTemp.length === 1){
            this.onSelectHours(arrStrHourTemp[0], true);
            this.setState({selectedtime:arrStrHourTemp[0]});
            this.setState({timeindex:1});
        }else{
            this.setState({timeindex:-1});
            let daylist = document.querySelectorAll("[href^='#hour']");
            daylist.forEach((obj) => {
                obj.classList.remove('active');
            });
        }


    };

    onSelectHours = (item, setHour) => {

        const { selHour } = this.state;
        const { selDay } = this.state;
        const { showEvents } = this.state;

        if (setHour){
            this.setState({
                selHour: item
            });
        }else{
            let hourlist = document.querySelectorAll("[href^='#hour']");
            var idxFinal = 0;
            hourlist.forEach((obj) => {
                if (item === obj.text){
                    let idxNew = obj.hash.slice(-1);
                    const convertNumber = Number(idxNew);
                    idxFinal = convertNumber + 1;
                }
            });
            this.setState({timeindex:idxFinal});
        }

        if (selHour === ""){
            if (showEvents){
                this.setState({
                    showEvents: false,
                    selHour: item
                });
            }
        }else{
            if (selHour === item){
                this.setState({
                    showEvents: false
                });
            }else{
                this.setState({
                    showEvents: false,
                    selHour: item
                });
            }
        }

        var arrEventSubscriptionTemp = [];

        var { itemsGroupedSubscription } = this.state;
        var { itemsGroupedSubscriptionTemp } = this.state;

        if (itemsGroupedSubscriptionTemp.length > itemsGroupedSubscription.length){
            itemsGroupedSubscription = itemsGroupedSubscriptionTemp;
        }

        for (var i=0; i < itemsGroupedSubscription.length; i++) {
            var event = itemsGroupedSubscription[i];

            var dateFromString = new Date(event.date_from);
            if (event.publication !== ""){
                dateFromString = new Date(event.publication);
            }

            const hourTemp = moment(new Date(dateFromString))
                .utc().format("LT");

            var day = new Date(dateFromString);

            //var time = dateFromString;
            //var t = new Date(time);
            /*var hr = ("0" + t.getHours()).slice(-2);
            var hourFinal = hr + ":00";*/

            const hourFinal = hourTemp;
            const dayFinal = day.toLocaleDateString('it-IT', { weekday: 'short' });

            if (dayFinal.toLowerCase() === this.state.selDay.toLowerCase() && hourFinal === this.state.selHour){

                var datePresaleStart = new Date(event.presale_start);
                //var datePresaleStartString = datePresaleStart.toLocaleDateString('it-IT');

                var datePresaleEnd = new Date(event.presale_end);
                //var datePresaleEndString = datePresaleEnd.toLocaleDateString('it-IT');

                var newDate = new Date();
                //const italianDate = new Date(new Date().toUTCString()).toISOString().toLocaleString('it-IT');
                //console.log(italianDate.toLocaleString('it-IT'));
                //console.log("italianDate");

                //var newDate = new Date().toLocaleDateString('it-IT');

                var before = this.isBefore(newDate, datePresaleEnd);
                var later = this.isLater(newDate, datePresaleStart);

                //var v1 = newDate;
                //var v2 = datePresaleStartString;
                //var v3 = datePresaleEndString;

                if (later && before) {
                    arrEventSubscriptionTemp.push(event);
                }

            }

        }

        setTimeout(()=>{
            if (arrEventSubscriptionTemp.length > 0){
                this.setState({
                    showEvents: false
                });
                this.setState({
                    isLoadedCard: true
                });
                this.setState({
                    itemsGroupedSubscription: arrEventSubscriptionTemp,
                });
                /*this.setState({
                    itemsGroupedSubscriptionFinal: arrEventSubscriptionTempFinal,
                });*/
            }else{
                this.setState({
                    showEvents: true
                });
            }
            this.setState({
                selDay: selDay
            });
            this.setState({
                selHour: item
            });
        },1500)


    };

    isLater(str1, str2)
    {
        return new Date(str1) > new Date(str2);
    }

    isBefore(str1, str2)
    {
        return new Date(str1) < new Date(str2);
    }

    toProperCase(s){
        return s.toLowerCase().replace(/^(.)|\s(.)/g,
            function($1) { return $1.toUpperCase(); });
    }

    onSelectEventInfo = (e, item) => {
        e.preventDefault();
        e.stopPropagation();

        let newEvent = null;
        newEvent = item;

        /*Object.keys(item).forEach((keys1)=>{
            if (item[keys1] instanceof Object){
                Object.keys(item[keys1]).forEach((keys2)=>{
                    let addedkey = keys1 + "_" + keys2;  console.log(addedkey);
                    let key = addedkey.split("_").join(" ");console.log(key);
                    key = this.toProperCase(key);
                    itemnew[key] = item[keys1][keys2]
                })
            }else{
                let key = keys1.split("_").join(" "); console.log(key);
                key = this.toProperCase(key);
                itemnew[key] = item[keys1]
            }
        });*/

        this.setState({selectedObjEvent:newEvent});
        this.handleShowInfoEvent(true);
    };

    onSelectEvent = (e, item) => {
        e.preventDefault();
        e.stopPropagation();

        var arr = [];
        if (this.state.itemsSelected.length > 0) {
            arr = this.state.itemsSelected;
        }

        if (arr.length > 0){
            var checkObj = arr.filter(obj => {
                return obj.slug === item.slug;
            });
            if (checkObj.length === 0){
                arr.push(item);
            }else{
                const indexObj = arr.findIndex(
                    obj => obj.slug === item.slug
                );
                arr.splice(indexObj, 1);
            }
        }else{
            arr.push(item);
        }

        if (!item.selectedEvent){
            item.selectedEvent = true;
        }else{
            item.selectedEvent = false;
        }

        this.setState({
            itemsSelected: arr
        });
        this.props.selectedEvents(arr, this.state.selDay, this.state.selHour, this.state.arrHours, this.state.maxItemsForOrder, this.state.reservationTime);
        this.handleCloseInfoEvent();

    };

    onDeselectAllEvent = () => {
        for (var i=0; i < this.state.itemsGroupedSubscription.length; i++) {
            let eventFinal = this.state.itemsGroupedSubscription[i];
            eventFinal.selectedEvent = false;
        }
        this.setState({
            itemsSelected: []
        });
        this.props.selectedEvents([], this.state.selDay, this.state.selHour, this.state.arrHours, this.state.maxItemsForOrder, this.state.reservationTime);
    };

    renderButtonHours() {
        var countHour = 0;
        return (
            <ListGroup horizontal>
                {this.state.itemsHour.map((item, idx) => (
                    this.state.arrStrHour.includes(item)
                        ? (
                            <ListGroup.Item
                                action href={["#hour" + countHour++]}
                                className = {this.state.timeindex === countHour++ ? 'active' :''}
                                onClick={(e) => this.onSelectHours(item, false)}>
                                <h5>{item}</h5>
                            </ListGroup.Item>
                        )
                        : (
                            ""/*<ListGroup.Item
                                action href={"#hourDisable" + idx}
                                className = "line-through-text"
                            >
                                <h5>{item}</h5>
                            </ListGroup.Item>*/
                        )
                ))}
            </ListGroup>
        );
    }

    renderGroupTipology() {

        if (this.state.itemsGroupedTipology.length > 0) {
            var arrTipology = [];
            for (var j = 0; j <= this.state.itemsGroupedTipology.length - 1; j++) {
                arrTipology.push(this.state.itemsGroupedTipology[j])
            }
            return (
                <div>
                    {arrTipology.map((keyEvent) =>(
                        <div>
                            {this.renderGroupTipologyEvent(keyEvent)}
                        </div>
                    ))}
                </div>
            );
        }

    }

    moveRight = (e, id) => {
        e.preventDefault();
        const elScroll = document.getElementById("scrollEvents" + id);
        elScroll.scrollLeft += 200;
    };

    moveLeft = (e,id) => {
        e.preventDefault();
        const elScroll = document.getElementById("scrollEvents" + id);
        elScroll.scrollLeft -= 200;
    };

    renderGroupTipologyEvent(keyEvent) {

        let dictEvent = [];

        for (var i=0; i < this.state.itemsGroupedSubscription.length; i++) {
            let eventFinal = this.state.itemsGroupedSubscription[i];
            let k = eventFinal.tipology;
            if (k === keyEvent){
                dictEvent.push(eventFinal);
            }
        }

        let replaceKey = keyEvent.replace(" ", "");

        if (dictEvent.length > 0){
            return (
                <div>
                    <h3 className="title">{keyEvent}</h3>
                    <div className="flex-container" id={"scrollEvents" + replaceKey} >
                        {dictEvent.map((item, i) =>(
                            <div className="scroll-horizontal">
                                <Card hidden={!this.state.isLoadedCard} onClick={(e) => this.onSelectEvent(e, item)}>
                                    <Card.Img variant="top" src={item.imgEvent !== ""  ? item.imgEvent : imgPlaceholder} className="img-event"  />
                                    <Card.Body className="card-data">
                                        <p className="p-card-thick p-card-nowrap mt-15-neg">{item.name.it}</p>
                                        <li className="date li-color-card mt-5-neg"><i className="far fa-calendar-alt"></i>&nbsp;
                                            {Functions.convertDate(item.date_from)}
                                        </li>
                                    </Card.Body>
                                    {item.selectedEvent === true &&
                                    <Badge bg="success"
                                           value={this.props.values.itemsEventSelected}><i className="far fa-check-circle"></i></Badge>
                                    }
                                    <Badge onClick={(e) => this.onSelectEventInfo(e, item)} className="button-circle-info" bg="secondary">
                                        <span className="icon info"></span></Badge>
                                </Card>
                                <Card hidden={this.state.isLoadedCard}>
                                    <Card.Img variant="top" src={imgPlaceholder} className="img-event" />
                                    <Card.Body className="card-data">
                                        <Placeholder as={Card.Title} animation="glow">
                                            <Placeholder xs={6} />
                                        </Placeholder>
                                        <Placeholder as={Card.Text} animation="glow">
                                            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                        </Placeholder>
                                    </Card.Body>
                                </Card>
                            </div>
                        ))}
                    </div>
                    {this.renderButtonArrows(replaceKey, dictEvent.length)}
                </div>

            );
        }

    }

    renderButtonArrows(replaceKey, numEvent) {
        if (numEvent > 2) {
            return (

                <section style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className="prevArrow" id={"prevArrow" + replaceKey} onClick={(e) => this.moveLeft(e,replaceKey)}>
                        <BsChevronLeft />
                    </div>
                    <div className="nextArrow" id={"nextArrow" + replaceKey} onClick={(e) => this.moveRight(e,replaceKey)}>
                        <BsChevronRight />
                    </div>
                </section>

            );
        }
    }


    renderEventInModal() {
        return(
            <div>
                {
                    this.state.selectedObjEvent === null
                        ? (
                            ""
                        )
                        : (
                            <div>
                                <div style = {{"overflow-y":"auto","height":"50vh"}}>
                                    <p className="p-card-thick-modal">{this.state.selectedObjEvent.name.it}</p>
                                    <p className="date li-color-card-modal"><i className="far fa-calendar-alt"></i>&nbsp;
                                        {Functions.convertDate(this.state.selectedObjEvent.date_from)}
                                    </p>
                                    <p className="title1-modal-event">Tipologia:&nbsp;</p>
                                    <p className="title2-modal-event">{this.state.selectedObjEvent.tipology}</p>
                                </div>
                                <Modal.Footer>
                                    {
                                        this.state.selectedObjEvent.selectedEvent === true
                                            ?(
                                                <Button onClick={(e) => this.onSelectEvent(e, this.state.selectedObjEvent)}>Deseleziona</Button>
                                            )
                                            : (
                                                <Button onClick={(e) => this.onSelectEvent(e, this.state.selectedObjEvent)}>Seleziona</Button>
                                            )
                                    }
                                </Modal.Footer>
                            </div>
                        )
                }
            </div>
        );
    }

    render() {

        const { msgError, isLoaded, showEvents, itemsHour, showHours, itemsSelected} = this.state;

        if (msgError) {
            return <div>Errore: {msgError.message}</div>;
        } else if (!isLoaded) {
            return <div><Spinner animation="border" role="status" variant="primary" >
                <span className="visually-hidden">Caricamento...</span>
            </Spinner></div>;
        }else {

            return(
                <>
                    <>
                        <ToastContainer
                            position="bottom-center"
                            autoClose={3000}
                            hideProgressBar={false}
                            closeOnClick
                            newestOnTop={true}
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            style={{ "zIndex":1000000}}
                        >
                        </ToastContainer>
                    </>
                    <fieldset>
                        <div className="form-card" id = "form-card">
                            <div>
                                <ListGroup horizontal>
                                    {list.map((item, idx) => (
                                        <ListGroup.Item
                                            action href={"#day" + idx}
                                            className = {this.state.nameindex === idx ? 'active' :''}
                                            onClick={(e) => this.onSelectDay(item,idx)}>
                                            <h5>{item.name}</h5>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                                <Tab.Content>
                                    {list.map((item, idx) => (
                                        <Tab.Pane eventKey={"#day" + idx}>
                                        </Tab.Pane>
                                    ))}
                                </Tab.Content>
                            </div>
                            <div hidden={showHours}>
                                {this.renderButtonHours()}
                                <Tab.Content>
                                    {itemsHour.map((item, idx) => (
                                        <Tab.Pane eventKey={"#hour" + idx}>
                                        </Tab.Pane>
                                    ))}
                                </Tab.Content>
                            </div>

                            <div className="container" hidden={showEvents}>
                                <div className="mt-30 text-center">
                                    <h5 className="title">Spettacoli selezionati &nbsp;<Badge bg="secondary">{itemsSelected.length}</Badge>
                                    </h5>
                                </div>
                                <div className='align-two-text-flex align-two-text-padding-top'>
                                    <p className="fs-subtitle cursor-pointer-text margin-left-deselect-event" onClick={this.onDeselectAllEvent}><img src={iconDeselect} alt="icona deseleziona eventi" height="30px" className="fs-subtitle-right icon-size-25" />&nbsp;Deseleziona</p>
                                </div>
                                {this.renderGroupTipology()}
                            </div>
                        </div>
                        <>
                            <Modal
                                show={this.state.showModalInfoEvent}
                                onHide={this.handleCloseInfoEvent}
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                style = {{"zIndex":"10000000","overflow-y":"auto","height":"100vh"}}

                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                        Dettaglio evento
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="show-grid">
                                    {this.renderEventInModal()}
                                </Modal.Body>
                            </Modal>
                        </>

                    </fieldset>
                </>
            );

        }

    }
}
