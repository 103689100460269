import React from 'react'

function FilterDropdown({ zones, findColorByCategory, setFilter, selectedZone }) {

    const getColor = zone => {
        return findColorByCategory(zone.rows?.[0]?.seats?.[0]?.category)
    }
    return (
        <div className="presense content-wrapper d-flex align-items-center flex-wrap">
            <div className="body w-100">

                <div className="role--item" onClick={() => setFilter("All")}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span className="item--body">
                            Filtra zone (tutte)
                        </span>
                    </div>
                    <input type="radio" className='filter-radio'
                           checked={selectedZone === 'All'}
                    />
                </div>
                {zones?.map?.((zone, index) =>(
                    <div className="role--item" onClick={() => setFilter(zone.name)} key={index.toString()}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className='zone-color' style={{ backgroundColor: getColor(zone) }} />
                            <span className="item--body">
                                {zone.name}
                            </span>
                        </div>
                        <input type="radio" className='filter-radio'
                               checked={selectedZone === zone.name}
                               readOnly
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default FilterDropdown;
