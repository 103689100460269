import React, {Component} from 'react';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import MainBannerSection from "../elements/main-banner";
import OrganizerComponent from "../elements/organizer-section";
import InfoSection from "../elements/info";

class Index extends Component{

	render(){
		return (
			<>

				<Header />

				<MainBannerSection />

				<InfoSection />

				<OrganizerComponent />

				<Footer />

			</>
		);
	}
}

export default Index;
