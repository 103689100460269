import React, { Component } from "react";

export default class ProgressbarSingleEvent extends Component {
    render() {
        const { step } = this.props;
        return (
            <ul id="progressbar">
                <li className={step >= 1 ? 'active progress-bar-25' : 'progress-bar-25'} id="tickets">
                    <strong>Biglietti</strong>
                </li>
                <li className={step >= 2 ? 'active progress-bar-25' : 'progress-bar-25'} id="personal">
                    <strong>Dati personali</strong>
                </li>
                <li className={step >= 3 ? 'active progress-bar-25' : 'progress-bar-25'} id="payment">
                    <strong>Pagamento</strong>
                </li>
                <li className={step === 4 ? 'active progress-bar-25' : 'progress-bar-25'} id="confirm">
                    <strong>Riepilogo</strong>
                </li>
            </ul>
        );
    }
}
