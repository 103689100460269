import React, { Component } from "react";

//pages wizard
import SelectPayment from "../pages/wizard/SelectPayment";
import SelectEventsSubscription from "./wizard/SelectEventsSubscription";
import Progressbar from "../pages/wizard/Progressbar";
import Success from "../pages/wizard/Success";
import SelectMapSubscription from "../pages/wizard/SelectMapSubscription";
import Seo from "../elements/seo";
import SelectUser from "./wizard/SelectUser";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";
import Utils from "../Utils";
import Functions from "../Functions";
import Messages from "../Messages";

export default class Form extends Component {

    constructor(props) {
        super(props);

        this.state = {
            step: 1,
            dateTimer: '',
            typeEvent: '',
            maxItemsForOrder: 0,
            reservationTime: 0,
            totalAmount: 0,
            totalSeat: 0,
            paymentMethod: '',
            ticketsSelected: [],
            arrUsers: [],
            arrCartPositions: [],
            countdownMinutes: 0,
            countdownSeconds: 0,
            countDownFinish: true,
            typeTicket: typeof(this.props.location.state) === 'undefined' || this.props.location.state === null ? [] : this.props.location.state.typeTicket,
            organizer: typeof(this.props.location.state) === 'undefined' || this.props.location.state === null ? [] : this.props.location.state.organizer,
            itemsEvent: typeof(this.props.location.state) === 'undefined' || this.props.location.state === null ? [] : this.props.location.state.events,
            itemsEventSelected: [],
            arrHours: [],
            cartPositions: []
        };

    }

    componentDidMount() {
        const urlWizard = window.location.href;
        const checkUrlWizard = urlWizard.includes("checkout");
        if (checkUrlWizard) {
            this.setState({
                step: 5
            });
        }else{
            this.countdownTimer();
        }

    }

    countdownTimer = () => {

        let dateAndhourCurrent = new Date();
        dateAndhourCurrent.setMinutes(dateAndhourCurrent.getMinutes() + 15);
        this.setState(
            {
                dateTimer: dateAndhourCurrent
            }
        );

        const timeInterval= setInterval(() => {
            const countdownDateTime = new Date(dateAndhourCurrent).getTime();
            const currentTime = new Date().getTime();
            const remainingDayTime = countdownDateTime - currentTime;
            const totalMinutes = Math.floor((remainingDayTime % (1000 * 60 * 60)) / (1000 * 60));
            const totalSeconds = Math.floor((remainingDayTime % (1000 * 60)) / 1000);

            this.setState(
                {
                    countdownMinutes: totalMinutes
                }
            );
            this.setState(
                {
                    countdownSeconds: totalSeconds
                }
            );

            if (remainingDayTime < 0) {
                clearInterval(timeInterval);
                this.setState(
                    {
                        countDownFinish: false
                    }
                );
            }

        }, 1000);

    };

    nextStep = (arrTicketsSelected, totTickets, objEvent, objOrganizer, arrUsersForTicket, dateT, arrCP, cP, arrEventsSelected, maxItems) => {
        if(this.state.countDownFinish !== false) {
            const { step } = this.state;

            this.setState({
                cartPositions: cP,
                maxItemsForOrder: maxItems,
                itemsEventSelected: arrEventsSelected,
                ticketsSelected: arrTicketsSelected,
                totalAmount: totTickets,
                event: objEvent,
                organizer: objOrganizer,
                arrUsers: arrUsersForTicket,
                dateTimer: dateT,
                arrCartPositions: arrCP
            });

            let numStep = step + 1;
            if (arrEventsSelected.length > 0) {
                if (cP.length > 0) {
                    for (var i=0; i < arrEventsSelected.length; i++) {
                        let event = arrEventsSelected[i];
                        if (typeof(event.jsonSettings) !== 'undefined' && event.jsonSettings !== null){
                            let requireNameAndSurname = event.jsonSettings.attendee_names_asked;
                            if (!requireNameAndSurname){
                                numStep = step + 2;
                                break;
                            }
                        }
                    }
                }
            }
            this.setState({
                step: numStep
            });
        }else{
            Functions.alertMsgError(Messages.MsgTimerExpired);
        }

    };

    prevStep = () => {
        const { step, itemsEventSelected, cartPositions} = this.state;
        let numStep = step - 1;
        if (itemsEventSelected.length > 0) {
            if (cartPositions.length > 0) {
                for (var i=0; i < itemsEventSelected.length; i++) {
                    let event = itemsEventSelected[i];
                    if (typeof(event.jsonSettings) !== 'undefined' && event.jsonSettings !== null){
                        let requireNameAndSurname = event.jsonSettings.attendee_names_asked;
                        if (!requireNameAndSurname){
                            numStep = step - 2;
                        }
                    }
                }
            }
        }

        this.setState({
            step: numStep
        });
    };

    handleChange = (input) => (e) => {
        this.setState({ [input]: e.target.value });
    };

    handlePaymentMethod = (method) => {
        this.setState({
            ...this.state, paymentMethod: method
        })
    }


    render() {
        const { step } = this.state;
        const { email,
            fname,
            lname,
            phno,
            paymentMethod, itemsEventSelected, selectedDay,
            selectedHour, arrUsers, itemsEvent, arrHours, arrCartPositions, cartPositions, totalAmount, totalSeat,
            maxItemsForOrder, reservationTime, dateTimer, propsSeo, organizer, typeTicket, countDownFinish } = this.state;

        const values = { email, fname, lname, phno, paymentMethod, itemsEventSelected,
            selectedDay, selectedHour, arrUsers, itemsEvent, arrHours, arrCartPositions, cartPositions, totalAmount, totalSeat,
            maxItemsForOrder, reservationTime, dateTimer, propsSeo, organizer, typeTicket, countDownFinish };

        let dict = {};
        dict["title"] = Utils.NAME_CUSTOMER + " | Sottoscrizione abbonamento";
        dict["description"] = Utils.NAME_CUSTOMER + " | Sottoscrizione abbonamento";

        return (
            <>
                <Seo propsSeo={dict}></Seo>
                <Header />
                <section className="section-area section-sp1 blog-area">
                    <form id="msform">
                        {}
                        <Progressbar step={step} />
                        <div className="App">
                            <div className="btn-group my-3">
                                <>
                                    <div className={Functions.renderClassTimer(this.state.countdownMinutes, this.state.countdownSeconds)}>{this.state.countdownMinutes}&nbsp;minuti&nbsp;:&nbsp;{this.state.countdownSeconds}&nbsp;secondi</div>
                                </>
                            </div>
                        </div>
                        {}
                        {
                            {
                                1: (
                                    <SelectEventsSubscription
                                        nextStep={this.nextStep}
                                        handleChange={this.handleChange}
                                        values={values}
                                    />
                                ),
                                2: (
                                    <SelectMapSubscription
                                        nextStep={this.nextStep}
                                        prevStep={this.prevStep}
                                        handleChange={this.handleChange}
                                        values={values}
                                    />
                                ),
                                3: (
                                    <SelectUser
                                        nextStep={this.nextStep}
                                        prevStep={this.prevStep}
                                        handleChange={this.handleChange}
                                        values={values}
                                    />
                                ),
                                4: (
                                    <SelectPayment
                                        nextStep={this.nextStep}
                                        prevStep={this.prevStep}
                                        values={values}
                                    />
                                ),
                                5: <Success values={values} />,
                            }[step]
                        }
                    </form>
                </section>
                <Footer />
            </>
        );
    }
}
