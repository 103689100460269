import React, {Component} from 'react';
import {Accordion} from 'react-bootstrap';
import Seo from "../elements/seo";
import Utils from "../Utils";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

//Faq, Faq page
class Faq extends Component{
	
	render(){
		let dict = {};
		dict["title"] = Utils.NAME_CUSTOMER + " | FAQ";
		dict["description"] = Utils.NAME_CUSTOMER + " | FAQ";

		return (
			<>

				<Seo propsSeo={dict}></Seo>
				<Header />

				<div className="page-content bg-white">

					<div className="banner-wraper">
						<div className="page-banner">
							<div className="container">
								<div className="page-banner-entry text-center">
									<h2>FAQ</h2>
								</div>
							</div>
						</div>
					</div>

					<section>
						<div className="container">
							<div className="contact-wraper">
								<div className="row">
									<div className="col-lg-6">
										<Accordion className="accordion ttr-accordion1">
											<Accordion.Item eventKey="0">
												<Accordion.Header>Pagamenti?</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Lorem Ipsum...</p>
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="1">
												<Accordion.Header>Prenotazioni?</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Lorem Ipsum...</p>
												</Accordion.Body>
											</Accordion.Item>

										</Accordion>
									</div>
									<div className="col-lg-6">
										<Accordion defaultActiveKey="2" className="accordion ttr-accordion1">
											<Accordion.Item eventKey="0">
												<Accordion.Header>Biglietti?</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Lorem Ipsum...</p>
												</Accordion.Body>
											</Accordion.Item>
										</Accordion>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section className="section-area section-sp1">
					</section>

				</div>


				
				<Footer />
				
			</>
		);
	}
}

export default Faq;