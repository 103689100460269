import React, { Component } from "react";

export default class Progressbar extends Component {
    render() {
        const { step } = this.props;
        return (
            <ul id="progressbar">
                <li className={step >= 1 ? 'active progress-bar-20' : 'progress-bar-20'} id="events">
                    <strong>Spettacoli</strong>
                </li>
                <li className={step >= 2 ? 'active progress-bar-20' : 'progress-bar-20'} id="mapevent">
                    <strong>Mappa</strong>
                </li>
                <li className={step >= 3 ? 'active progress-bar-20' : 'progress-bar-20'} id="personal">
                    <strong>Dati personali</strong>
                </li>
                <li className={step >= 4 ? 'active progress-bar-20' : 'progress-bar-20'} id="payment">
                    <strong>Pagamento</strong>
                </li>
                <li id="confirm" className={step === 5 ? 'active progress-bar-20' : 'progress-bar-20'}>
                    <strong>Riepilogo</strong>
                </li>
            </ul>
        );
    }
}
