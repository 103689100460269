import React, {Component} from 'react';
import Seo from "../elements/seo";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";
import Utils from "../Utils";

//Terms, Terms page
class Terms extends Component{


    render(){
        let dict = {};
        dict["title"] = Utils.NAME_CUSTOMER + " | Termini e Condizioni";
        dict["description"] = Utils.NAME_CUSTOMER + " | Termini e Condizioni";

        return (
            <>
                <Seo propsSeo={dict}></Seo>
                <Header />

                <div className="page-content bg-white">

                    <div className="banner-wraper">
                        <div className="page-banner banner-lg contact-banner">
                            <div className="container">
                                <div className="page-banner-entry text-center">
                                    <h2>Termini e Condizioni</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section>
                        <div className="container">
                            <div className="contact-wraper">
                                <div className="row">
                                    <div className="icon-content">
                                        <h5 className="ttr-title">Titolare del Sito</h5>
                                        <p>I servizi del portale <a href="https://webapp.coll.ticalo.it" target="_blank" rel="noreferrer">https://webapp.coll.ticalo.it</a> sono forniti da <i>Bluenet S.r.l.</i> e l'utente stipula il contratto d’uso con la società <i>Bluenet S.r.l.</i>, d’ora in avanti denominata “<i>Bluenet</i>”.  Il portale fa parte del sistema di prenotazioni di abbonamenti teatrali denominato <strong><i className="text-color-secondary">Ticalo</i></strong>, che è di proprietà della Bluenet. La <i>Bluenet</i> gestisce le prenotazioni degli abbonamenti teatrali online tramite questo portale per conto del Teatro Cilea.
                                        </p>

                                        <h5 className="ttr-title">Condizioni d’Uso</h5>
                                        <p>L’utilizzo del portale <a href="https://webapp.coll.ticalo.it" target="_blank" rel="noreferrer">https://webapp.coll.ticalo.it</a> è valido esclusivamente per effettuare prenotazioni di abbonamenti teatrali del Teatro Cilea in conformità delle leggi della Repubblica Italiana.  Ogni utente di questo portale è soggetto alle presenti Termini e Condizioni di Utilizzo. Il semplice utilizzo del portale da parte dell’utente comporta l’accettazione dei presenti Termini e Condizioni di Utilizzo, con effetto a partire dal primo accesso.
                                        </p>
                                        <p>La prenotazione sul sistema <strong><i className="text-color-secondary">Ticalo</i></strong> richiede la registrazione della carta di pagamento sul portale <strong><i className="text-color-secondary">Stripe</i></strong>, per facilitare il futuro acquisto dell’abbonamento teatrale.  La carta registrata verrà addebitata esclusivamente quando il sistema di biglietteria del Teatro Cilea avrà emesso l’abbonamento.  I dati della carta di pagamento registrati sono gestiti esclusivamente dalla <strong><i className="text-color-secondary">Stripe, Inc.</i></strong> (termini e condizione: <a href="https://stripe.com/en-it/legal#translation" target="_blank" rel="noreferrer">https://stripe.com/en-it/legal#translation</a>) di cui è responsabile del trattamento in conformità delle leggi europee sulla riservatezza dei dati (<a href="https://stripe.com/privacy" target="_blank" rel="noreferrer">https://stripe.com/privacy</a>).
                                        </p>

                                        <h5 className="ttr-title">Collegamento a Siti Esterni</h5>
                                        <p>Eventuali collegamenti a siti internet diversi da https://webapp.coll.ticalo.it presenti nel portale possono essere modificati, spostati o eliminati dai legittimi proprietari senza che ne venga dato alcun avviso a <i>Bluenet</i>.
                                        </p>

                                        <h5 className="ttr-title">Diritti di Proprietà Intellettuale</h5>
                                        <p>Tutti i diritti sono riservati. I contenuti inclusi in questo sito, sono di proprietà della <i>Bluenet</i> o del Teatro Cilea, e sono protetti dalle leggi internazionali sul copyright.  La formattazione e compilazione di tutti i contenuti di questo sito è di proprietà esclusiva di <i>Bluenet</i>, con ne detiene la paternità del copyright protetta da leggi internazionali sul diritto d'autore.  La proprietà intellettuale del sistema <strong><i className="text-color-secondary">Ticalo</i></strong> è della <i>Bluenet</i>.
                                        </p>
                                        <p>I marchi e loghi di Bluenet non possono essere utilizzati in relazione ad alcuno prodotto o servizio senza l’autorizzazione di <i>Bluenet</i>, in qualsiasi modo possa causare confusione tra i clienti, o in qualsiasi modo che denigra o scredita la <i>Bluenet</i>. Tutti gli altri marchi non di proprietà di <i>Bluenet</i>. o delle sue sussidiarie che appaiono su questo sito sono di proprietà dei rispettivi proprietari, che possono o possono non essere affiliato, collegato o sponsorizzato da <i>Bluenet</i> o dai suoi filiali.
                                        </p>

                                        <h5 className="ttr-title">Modifica dei Termini e Condizioni</h5>
                                        <p><i>Bluenet S.r.l.</i> si riserva il diritto di modificare e/o integrare in qualsiasi momento e senza preavviso i presenti Termini e Condizioni, dandone comunicazione nella propria home-page. In tal caso, le modifiche saranno efficaci agli effetti di legge, senza la necessità di specifica e ulteriore approvazione.  L’utilizzo del Portale in un momento successivo all’apporto di tali modifiche e integrazioni implica la completa accettazione da parte dell’utente dei nuovi Termini e Condizioni. Si invita, pertanto, l’utente di verificare costantemente eventuali modifiche e integrazioni apportate ai Termini e Condizioni di volta in volta in vigore e a non utilizzare il Portale qualora egli non condivida o non intenda accettare, anche solo parzialmente, i nuovi Termini e Condizioni.
                                        </p>

                                        <h5 className="ttr-title">Esclusione di Responsabilità</h5>
                                        <p>Gli utenti del portale <a href="https://webapp.coll.ticalo.it" target="_blank" rel="noreferrer">https://webapp.coll.ticalo.it</a> sono obbligati di assicurarsi che il loro computer sia adeguatamente protetto contro virus, accessi non autorizzati o altre violazioni della sicurezza. La <i>Bluenet</i> non è responsabile per qualsiasi danno che possa derivare a carico del computer di ciascun utente da qualunque violazione della sicurezza o da qualunque virus, bug, manomissione, intervento non autorizzato, truffa, errore, omissione, interruzione, cancellazione, difetto, ritardo nelle operazioni o trasmissioni, guasto delle linee del computer o qualsiasi altro difetto tecnico o di altro tipo, qualora un tale evento sia posto in relazione con la trasmissione internet iniziata attraverso il sito web o in altro modo e che non siano un risultato diretto o indiretto della negligenza di <i>Bluenet</i>.
                                        </p>

                                        <h5 className="ttr-title">Requisiti di Età</h5>
                                        <p>Se l'utente non è di maggiore età, deve avere l'autorizzazione di un genitore o tutore legale per poter usare i servizi offerti da <strong><i className="text-color-secondary">Ticalo</i></strong>. In questo caso, invitiamo l'utente a leggere i presenti termini insieme a un genitore o tutore legale.  Se un genitore o tutore legale consente al figlio di usare i servizi, i presenti termini si applicano al genitore o tutore, che è inoltre responsabile dell'attività svolta dal figlio sui servizi.
                                        </p>

                                        <h5 className="ttr-title">Ultimo Aggiornamento</h5>
                                        <p>Le presenti condizioni generali sono state modificate da ultimo in data 03/03/2023.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="section-area section-sp1">
                    </section>

                </div>



                <Footer />

            </>
        );
    }
}

export default Terms;
