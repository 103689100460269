import React, { Component} from "react";
import 'moment/locale/it';
import 'moment-timezone';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

// Elements
import SeatMap from "./SeatMap/SeatMap";
import ButtonWizard from "./ButtonWizard";
import Messages from "../../Messages";
import Functions from "../../Functions";

export default class SelectMapSubscription extends Component {

    constructor(props) {
        super(props);

        this.state = {
            totalAmount: 0,
            cartPositionsList: [],
            arrSeats: [],
            dateTimer: ''
        };

    }

    componentDidMount() {

    }

    continue = (e) => {

        e.preventDefault();
        if (this.state.arrSeats.length > 0){

            var arrCartPositions = [];
            for (var i=0; i < this.state.cartPositionsList.length; i++) {
                var keySeatGuid = Object.keys(this.state.cartPositionsList[i])[0];
                let cartPositions = this.state.cartPositionsList[i][keySeatGuid];
                arrCartPositions.push(cartPositions);
            }

            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            this.props.nextStep([], this.state.totalAmount, null, this.props.values.organizer, [], this.state.reservationTime, arrCartPositions, this.state.cartPositionsList, this.props.values.itemsEventSelected, this.props.values.maxItemsForOrder);

        }else{
            Functions.alertMsgError(Messages.MsgSelectOneSeat);
        }
    };

    back = (e) => {
        e.preventDefault();
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        this.props.prevStep();
    };

    getSeatsSelected = (arr, totAmount) => {
        this.setState({
            arrSeats: arr,
            totalAmount: totAmount,
            //cartPositionsList: arrCP
        });
    };

    getCartPositions = (arr) => {
        this.setState({
            cartPositionsList: arr
        });
    };

    render() {
        const mailto = "mailto:" + this.props.values.organizer.mailOrganizer;

        return(
            <>
                <>
                    <ToastContainer
                        position="bottom-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        closeOnClick
                        newestOnTop={true}
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        style={{ "zIndex":1000000}}
                    >
                    </ToastContainer>
                </>
                <fieldset>
                    <div className="page-content bg-white">
                        <section className="section-area section-sp7">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-9 mb-30">
                                        <SeatMap values={this.props.values} sendseats={this.getSeatsSelected} sendcartpositions={this.getCartPositions} />
                                    </div>
                                    <div className="col-lg-3">
                                        <aside className="sticky-top pb-1">
                                            <div className="widget">
                                                <ul className="service-menu">
                                                    <h6>Organizzatore</h6>
                                                    <p className="p-card-thick">{this.props.values.organizer.name}</p>
                                                    <img src={this.props.values.organizer.imgOrganizer} className="img-event-sidebar" alt="immagine dell'organizzatore" />
                                                    <p className="title1-modal-event">Contatti:&nbsp;</p>
                                                    <p className="title2-modal-event truncate-event"><a href={mailto}>{this.props.values.organizer.mailOrganizer}</a></p>
                                                </ul>
                                            </div>
                                        </aside>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <ButtonWizard btnClicked={this.back} text="Indietro" />
                        <ButtonWizard btnClicked={this.continue} text="Avanti" />
                    </div>

                </fieldset>
            </>
        );

    }

}
