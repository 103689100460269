import React, { Component} from "react";
import 'moment/locale/it';
import 'moment-timezone';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

// Elements
import ButtonWizard from "./ButtonWizard";
import Functions from "../../Functions";
import Utils from "../../Utils";
import Messages from "../../Messages";
import Entertainment from "./SelectEntertainment";

//images
import imgPlaceholder from "../../../images/placeholderTicalo.png";

export default class SelectEventsSubscription extends Component {

    constructor(props) {
        super(props);

        this.state = {
            itemsSelected: [],
            selDay : "",
            selHour : "",
            itemsHour: [],
            maxItemsForOrder: "",
            reservationTime: ""
        };

    }

    componentDidMount() {

    }

    continue = (e) => {

        e.preventDefault();
        if (this.state.itemsSelected.length >= Utils.MinItemSelected && this.state.itemsSelected.length <= Utils.MaxItemSelected){
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            this.props.nextStep([], 0, null, this.props.values.organizer, [], this.state.reservationTime, [], [], this.state.itemsSelected, this.state.maxItemsForOrder);
        }else{
            Functions.alertMsgError(Messages.MsgErrorSelectedEventsMinAndMax);
        }

    };

    getEventsSelected = (arr, day, hour, arrHours, maxItemsForOrder, reservationTime) => {
        this.setState({
            itemsSelected: arr,
            selDay: day,
            selHour: hour,
            itemsHour: arrHours,
            maxItemsForOrder: maxItemsForOrder,
            reservationTime: reservationTime
        });
    };

    render() {

        const mailto = "mailto:" + this.props.values.organizer.mailOrganizer;

        return(
            <>
                <>
                    <ToastContainer
                        position="bottom-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        closeOnClick
                        newestOnTop={true}
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        style={{ "zIndex":1000000}}
                    >
                    </ToastContainer>
                </>
                <fieldset>
                    <div className="page-content bg-white">
                        <section className="section-area section-sp7">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-9 mb-30">
                                        <Entertainment values={this.props.values} selectedEvents={this.getEventsSelected} />
                                    </div>
                                    <div className="col-lg-3">
                                        <aside className="sticky-top pb-1">
                                            <div className="widget">
                                                <ul className="service-menu">
                                                    <h6>Organizzatore</h6>
                                                    <p className="p-card-thick">{this.props.values.organizer.name}</p>
                                                    <img src={this.props.values.organizer.imgOrganizer !== ""  ? this.props.values.organizer.imgOrganizer : imgPlaceholder}
                                                         className="img-event-sidebar" alt="immagine dell'organizzatore" />
                                                    <p className="title1-modal-event">Contatti:&nbsp;</p>
                                                    <p className="title2-modal-event truncate-event"><a href={mailto}>{this.props.values.organizer.mailOrganizer}</a></p>
                                                </ul>
                                            </div>
                                        </aside>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <ButtonWizard btnClicked={this.continue} text="Avanti" />
                    </div>

                </fieldset>
            </>
        );

    }

}
