import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

var firebaseConfig = {
    apiKey: "AIzaSyCXeVlwpCHZK6LqXy3jWSkcm-q8GFPdNjQ",
    authDomain: "ticalo.firebaseapp.com",
    projectId: "ticalo",
    storageBucket: "ticalo.appspot.com",
    messagingSenderId: "1039459971528",
    appId: "1:1039459971528:web:b3737b36bc84b51994d0fd"
};

firebase.initializeApp(firebaseConfig);

export default firebase
