import { Component } from 'react';

//Messages, component with all the messages of the web app
class Messages extends Component{

    static MsgErrorServer = 'Errore server';
    static MsgErrorGeneral = 'Errore generale';
    static MsgErrorInsertMail = 'Email non inserita';
    static MsgErrorValidMail = 'Email non valida';
    static MsgErrorMap = 'Nessuna mappa';
    static MsgErrorFailedLoad = 'Caricamento fallito...';
    static MsgErrorPageNotLoadedCorrectly = 'Pagina non caricata correttamente';
    static MsgErrorNoEvents = 'Eventi non disponibili';
    static MsgErrorSelectedEventsMinAndMax = 'Devi selezionare almeno 10 eventi con un massimo di 20 eventi';
    static MsgErrorNoSectors = 'Fallito il caricamento dei settori';
    static MsgSeatBlocked = 'Il posto è stato bloccato da un altro utente';
    static MsgSeatDelete = 'Non è stato possibile eliminare il posto';
    static MsgDuplicateUser = "Hai inserito più volte lo stesso nominativo con data e luogo di nascita per più biglietti oppure già presente nel nostro sistema";
    static MsgErrorNoTicketsByEvent = 'Non esistono biglietti per questo evento';
    static MsgErrorNoRetrieveTicketsByEvent = 'Non è stato possibile recuperare i biglietti dell\'evento';
    static MsgErrorNoRetrieveCategoriesByEvent = 'Non è stato possibile recuperare le categorie dell\'evento';
    static MsgErrorNoRetrieveQuotasByEvent = 'Non è stato possibile recuperare le quote dell\'evento';
    static MsgErrorTotTicketsBySector = 'Non puoi più aggiungere biglietti al carrello perchè il totale di biglietti del settore è esaurito.';
    static MsgErrorAddTicketsInCartLimit = 'Non puoi più aggiungere biglietti al carrello perchè hai raggiunto il limite di disponibilità.';
    static MsgErrorAddTicketsInCartOutStock = 'Non puoi più aggiungere biglietti al carrello perchè sono esauriti.';
    static MsgErrorLinkPayment = 'Non è stato possibile generare il link di pagamento';
    static MsgTimerExpired = 'Tempo scaduto. Effettua di nuovo la configurazione dall\'inizio.';
    static MsgSelectOneTicket = 'Devi selezionare almeno 1 biglietto';
    static MsgSelectOneSeat = 'Devi selezionare almeno 1 posto';
    static MsgCompileAllTickets = 'Devi compilare tutti i dati  dei biglietti';
    static MsgCompileAllData = 'Devi compilare tutti i dati';
    static MsgCompileOneTicket = 'Non hai compilato tutti i dati del biglietto';
    static MsgUnverifiedPhoneNumber = 'Numero non verificato. Riprova...se il problema persiste, contatta l\'assistenza!';
    static MsgUnverifiedOTP = 'OTP non verificato. Riprova...se il problema persiste, contatta l\'assistenza!';
    static MsgErrorNameAndSurname = 'Nome o Cognome contenente caratteri speciali';
    static MsgErrorTotal = 'Errore totale';
    static MsgErrorProductsOnline = 'Non ci sono prodotti disponibili vendibili online';

}

export default Messages;
